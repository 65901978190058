import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/index.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VConsole from 'vconsole';
// const vConsole = new VConsole({ theme: 'dark' });

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vant);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
