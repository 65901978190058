<script>
import {apiIndexIndexSeoInfo} from "@/request/public";

export default {
  mounted() {
    apiIndexIndexSeoInfo({}).then((res) => {
      if (this.$route.path === '/chongyang') {
        document.title = "请您领取重阳节福利";
      } else {
        document.title = res.data && res.data.site_name || "";
      }

    }).catch((err) => {
      console.log(err);
    });
  }
};
</script>
<template>
  <router-view/>
</template>
