const storage_key = "TIHUO_YUN_USE_CARD_INFO";

//设置卡券信息
export function set_card_info(obj) {
    let str = JSON.stringify(obj);
    localStorage.setItem(storage_key, str);
}

//获取卡券信息
export function get_card_info() {
    let str = localStorage.getItem(storage_key);
    return JSON.parse(str || "{}") || {};
}

//清除卡券信息
export function clear_card_info() {
    localStorage.removeItem(storage_key);
}

/**
 * @function get_card_number
 * @description 获取当前使用(登录)的卡券卡号
 * @return {string} - 当前使用(登录)的卡券卡号
 */
export function get_card_number() {
    let str = localStorage.getItem(storage_key);
    let obj = JSON.parse(str || "{}") || {};
    return obj?.card_number || "";
}

/**
 * @function get_card_token
 * @description 获取token
 * @return {string} - token
 */
export function get_card_token() {
    let str = localStorage.getItem(storage_key);
    let obj = JSON.parse(str || "{}") || {};
    return obj?.token || "";
}